<template>
  <div class="m-2">
    <v-row justify="center">
      <v-col cols="12" lg="8" sm="8" md="8">
        <v-card color="teal darken-1" dark>
          <v-card-text>
            <v-row class="mt-1 p-1 mb-0">
              <v-col
                v-for="(v, i) in master.demografi"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-1 mb-1 pt-1 pb-1"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.demografi[i] = e)"
                  :value="data.demografi[i]"
                  vclass="c-text-field"
                  :data="v.data"
                  :disabled="v.disabled"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-divider class="mt-3 mb-3" />
        <div class="font-weight-bold text-center">RENCANA OPERASI</div>
        <v-divider class="mt-3 mb-3" />
        <v-row class="mt-0 mb-0">
          <v-col
            v-for="(v, i) in master.form1"
            :md="v.col ? v.col : 12"
            :lg="v.col ? v.col : 12"
            :sm="v.col ? v.col : 12"
            cols="12"
            class="mt-0 mb-0 pt-0 pb-3"
            :key="i"
          >
            <smart-widget
              :comp="v.widget"
              :sync-value="(e) => (data.form1[i] = e)"
              :value="data.form1[i]"
              vclass="c-text-field"
              :data="v.data"
              :disabled="v.disabled"
              :property="v.property"
              :label="v.label ? v.label : i.replaceAll('_', ' ')"
              :rules="[rules.required]"
            />
          </v-col>
        </v-row>
        <v-divider class="mt-3 mb-3" />
        <div class="font-weight-bold text-center">RENCANA OPERASI</div>
        <v-divider class="mt-3 mb-3" />
        <v-row class="mt-0 mb-0">
          <v-col
            v-for="(v, i) in master.form2"
            :md="v.col ? v.col : 12"
            :lg="v.col ? v.col : 12"
            :sm="v.col ? v.col : 12"
            cols="12"
            class="mt-0 mb-0 pt-0 pb-3"
            :key="i"
          >
            <smart-widget
              :comp="v.widget"
              :sync-value="(e) => (data.form2[i] = e)"
              :value="data.form2[i]"
              vclass="c-text-field"
              :data="v.data"
              :property="v.property"
              :label="v.label ? v.label : i.replaceAll('_', ' ')"
              :rules="[rules.required]"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" lg="4" sm="4" md="4"></v-col>
    </v-row>
    <a href="#test4" id="test4"></a>
    <div class="form-group">
      <div
        style="z-index: 999999"
        class="float-group btn-group-vertical"
        role="group"
        aria-label="Vertical button group"
      >
        <v-btn @click="postSave" color="primary" large class="btn-block">
          Simpan Form
        </v-btn>
        &nbsp;
      </div>
    </div>
    <dialog-confirm
      :show-dialog="dialogShow"
      :negative-button="dialogActionNBtn"
      :positive-button="dialogActionPBtn"
      :disabled-negative-btn="dialogDisableNBtn"
      :disabled-positive-btn="dialogDisablePBtn"
      :progress="dialogProgress"
      :title="dialogTitle"
      :message="dialogMessage"
    />
    <!-- <div v-html="jsonPrettier(data)"></div> -->
  </div>
</template>

<script>
import SmartWidget from '@/components/SmartWidget.vue'
import Master from '../../data/form-tindakan-operasi'
import Dialog from '@/components/Dialog'
import {
  // eslint-disable-next-line no-unused-vars
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  errorMsg,
  // eslint-disable-next-line no-unused-vars
  setBtnLoading,
  // eslint-disable-next-line no-unused-vars
  resetBtnLoading
} from '../../plugins/supports'
export default {
  components: {
    DialogConfirm: Dialog,
    SmartWidget
  },
  data () {
    return {
      user: JSON.parse(localStorage.getItem('user')),

      ayam: '',
      procedurs: [
        {
          previous: '',
          expiration: ''
        }
      ],
      master: Master,
      data: {
        demografi: {
          nama_pasien: '',
          no_rekam_medis: '',
          tanggal_lahir: '',
          cara_bayar: '',
          ruangan: '',
          kamar_kelas: '',
          jenis_kelamin: '',
          folio_id: '',
          sub_unit_id: '',
          reg_id: ''
        },
        form1: {
          p1: '',
          p2: '',
          p3: '',
          p4: '',
          p5: '',
          p6: '',
          p7: '',
          p8: '',
          p9: '',
          p10: ''
        },
        form2: {
          p1: '',
          p2: '',
          p3: '',
          p4: '',
          p5: ''
        }
      },
      rules: {
        required: (value) => {
          // return !isEmpty(value) || 'Tidak Boleh Kosong'
          return true
        },
        dialogShow: false,
        dialogMessage: 'Simpan Form Sekarang?',
        dialogProgress: false,
        dialogDisableNBtn: false,
        dialogDisablePBtn: false
      },
      dialogShowSoap: false,
      dialogTitleSoap: 'SOAP',
      historySubjektif: '',
      historyObjektif: '',
      historyAsessmen: '',
      planningAsessmen: '',
      dialogActionNBtnSoap: () => {
        this.dialogShowSoap = false
      },
      dialogActionPBtnSoap: () => {},

      dialogDisableNBtnSoap: false,
      dialogDisableNBtnKirimResume: false,

      dialogActionNBtnLKirimResume: () => {
        this.dialogResume = false
      },
      dialogDisablePBtnSoap: false,

      dialogShow: false,
      dialogTitle: 'Simpan',
      dialogMessage: 'Simpan Form Sekarang?',
      dialogProgress: false,
      dialogDisableNBtn: false,
      dialogDisablePBtn: false,
      dialogActionNBtn: () => {
        this.dialogShow = false
      },

      dialogActionPBtn: () => this.postSave()
    }
  },
  created () {
    if (this.$route.query.no_rm != null) {
      this.getDataPasien(this.$route.query.folio_id)
      this.getAssemen(this.$route.query.folio_id)
      // setTimeout(() => {
      //   this.getAssemen(this.$route.query.folio_id)
      // }, 100)
    }
  },
  methods: {
    postSave () {
      // const formData = new FormData()
      // formData.append(
      //   'data',
      //   new Blob([JSON.stringify(this.data)], { type: 'application/json' })
      // )
      // console.log(JSON.parse(JSON.stringify(this.data)))

      // console.log(this.data)
      // eslint-disable-next-line no-unused-vars
      var data = {
        rm: this.$route.query.no_rm,
        folio_id: this.$route.query.folio_id,
        regis_id: this.$route.query.registration_id,
        sub_unit: this.$route.query.sub_unit_id,
        data: this.data,
        jenis: 'rawat-inap',
        created_by: this.user.employee_id,

        type: 'Tindakan Kamar Operasi'
      }
      // eslint-disable-next-line no-undef
      $.post(
        baseUrl + 'rawat-inap/simpan-form-tindakan',
        data,
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            successMsg(msg)
          } else {
            errorMsg(msg)
          }
        },
        'JSON'
      )
    },
    addPro () {
      this.data.procedurs.push({
        previous: '',
        expiration: ''
      })
    },
    deletePro (counter) {
      this.data.procedurs.splice(counter, 1)
    },
    getDataPasien (folioId) {
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'rawat-inap/get-detail-pasien',
        { folio_id: folioId },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            this.data.demografi = results
            this.data.demografi.jenis_kelamin = {
              value: results.jenis_kelamin,
              text: results.jenis_kelamin
            }
            this.data.form1.p1 = this.data.demografi.nama_pasien
            this.data.form1.p2 = this.data.demografi.no_rekam_medis
            this.data.form1.p3 = this.data.demografi.cara_bayar
            this.data.form1.p4 =
              this.data.demografi.kamar_kelas +
              '-' +
              this.data.demografi.ruangan
            // this.data.form4 = results.form4
            // this.data =
          } else {
          }
        },
        'JSON'
      )
    },
    getAssemen (folioId) {
      var type = 'Tindakan Kamar Operasi'
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'rawat-inap/get-form-tindakan',
        { folio_id: folioId, jenis: type },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            this.data = results
          } else {
            this.data.form1.p1 = this.data.demografi.nama_pasien
            this.data.form1.p2 = this.data.demografi.no_rekam_medis
            this.data.form1.p3 = this.data.demografi.cara_bayar
            this.data.form1.p4 =
              this.data.demografi.kamar_kelas +
              '-' +
              this.data.demografi.ruangan
            // this.data.form4 = results.form4
            // this.data =
          }
        },
        'JSON'
      )
    }
  }
}
</script>

<style scoped>
.float-group {
  position: fixed;
  bottom: 5%;
  right: 40px;
  color: #fff;
  text-align: center;
  z-index: 100;
}

.float {
  position: fixed;
  bottom: 160px;
  right: 40px;
  color: #fff;
  text-align: center;
  z-index: 100;
}
</style>
