export default {
  demografi: {
    nama_pasien: {
      label: 'Nama Pasien',
      widget: 'wtext',
      data: null,
      disabled: true,
      col: 3
    },
    no_rekam_medis: {
      label: 'No Rekam Medis',
      widget: 'wtext',
      disabled: true,

      data: null,
      col: 3
    },
    tanggal_lahir: {
      label: 'Tangga Lahir',
      widget: 'wdatenormal',
      data: null,
      disabled: true,

      col: 3
    },
    cara_bayar: {
      label: 'Cara Bayar',
      widget: 'wtext',
      disabled: true,

      data: [],
      col: 3
    },
    ruangan: {
      widget: 'wtext',
      data: null,
      label: 'Ruangan',
      disabled: true,

      col: 3
    },
    kamar_kelas: {
      widget: 'wtext',
      data: null,
      label: 'Kamar Kelas',
      disabled: true,

      col: 3
    },
    jenis_kelamin: {
      widget: 'wradio',
      disabled: true,

      data: [
        { text: 'Laki-Laki', value: 'laki-laki' },
        { text: 'Wanita', value: 'wanita' }
      ],
      col: 6
    },
    dpjp_utama: {
      widget: 'wtext',
      data: null,
      disabled: true,

      label: 'Dokter DPJP Utama',
      col: 6
    },
    dpjp_pembantu: {
      widget: 'wtext',
      disabled: true,

      data: null,
      label: 'Dokter DPJP',
      col: 6
    },
    folio_id: {
      widget: 'wtext',
      data: null,
      disabled: true,

      col: 4,
      label: 'No Folio'
    },
    sub_unit_id: {
      widget: 'wtext',
      disabled: true,

      data: null,
      col: 2,
      label: 'Sub Unit Dokter'
    },
    reg_id: {
      widget: 'wtext',
      disabled: true,

      data: null,
      label: 'No Reg',
      col: 4
    }
  },
  form1: {
    p1: {
      widget: 'wtext',
      data: null,
      col: 6,
      disabled: true,
      label: 'Nama Pasien'
    },
    p2: {
      widget: 'wtext',
      data: null,
      col: 6,
      disabled: true,

      label: 'No. Rekam Medis'
    },
    p3: {
      widget: 'wtext',
      data: null,
      col: 6,
      disabled: true,

      label: 'Pembayaran'
    },
    p4: {
      widget: 'wtext',
      data: null,
      col: 6,
      disabled: true,

      label: 'Kelas / ODS'
    },
    p5: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: 'Diagnosa'
    },
    p6: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: 'Nama Tindakan'
    },
    p7: {
      widget: 'wradio',
      data: [
        { text: 'Kecil Non Anastesi', value: 'Kecil Non Anastesi' },
        { text: 'Kecil Anastesi', value: 'Kecil Anastesi' },
        { text: 'Sedang', value: 'Sedang' },
        { text: 'Besar', value: 'Besar' },
        { text: 'Khusus', value: 'Khusus' },
        { text: 'Canggih', value: 'Canggih' }
      ],
      col: 6,
      label: 'Jenis Operasi'
    },
    p8: {
      widget: 'wtextarea',
      data: null,
      col: 6,
      label: 'Alkes'
    },
    p9: {
      widget: 'wautocomplatesingle',
      data: null,
      col: 6,
      label: 'Operator I'
    },
    p10: {
      widget: 'wautocomplatesingle',
      data: null,
      col: 6,
      label: 'Operator II'
    }
  },
  form2: {
    p1: {
      widget: 'wradio',
      data: [
        { text: 'Kecil Non Anastesi', value: 'Kecil Non Anastesi' },
        { text: 'Kecil Anastesi', value: 'Kecil Anastesi' },
        { text: 'Sedang', value: 'Sedang' },
        { text: 'Besar', value: 'Besar' },
        { text: 'Khusus', value: 'Khusus' },
        { text: 'Canggih', value: 'Canggih' }
      ],
      col: 6,
      label: 'Perubahan Jenis Operasi'
    },
    p2: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: 'Alkes'
    },
    p5: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: 'Nama Tindakan'
    },
    p3: {
      widget: 'wautocomplatesingle',
      data: null,
      col: 6,
      label: 'Operator I'
    },
    p4: {
      widget: 'wautocomplatesingle',
      data: null,
      col: 6,
      label: 'Operator II'
    }
  }
}
