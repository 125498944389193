<template>
  <div class="m-2">
    <v-row justify="center">
      <v-col cols="12" lg="8" sm="8" md="8">
        <v-card color="teal darken-1" dark>
          <v-card-text>
            <v-row class="mt-1 p-1 mb-0">
              <v-col
                v-for="(v, i) in master.demografi"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-1 mb-1 pt-1 pb-1"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.demografi[i] = e)"
                  :value="data.demografi[i]"
                  vclass="c-text-field"
                  :data="v.data"
                  :disabled="v.disabled"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-row class="mt-4 m-1 mb-0">
          <v-col
            v-for="(v, i) in master.form1"
            :md="v.col ? v.col : 12"
            :lg="v.col ? v.col : 12"
            :sm="v.col ? v.col : 12"
            cols="12"
            class="mt-0 mb-0 pt-0 pb-3"
            :key="i"
          >
            <smart-widget
              :comp="v.widget"
              :sync-value="(e) => (data.form1[i] = e)"
              :value="data.form1[i]"
              vclass="c-text-field"
              :data="v.data"
              :disabled="v.disabled"
              :property="v.property"
              :label-size="v.labelSize"
              :input-size="v.inputSize"
              :label="v.label ? v.label : i.replaceAll('_', ' ')"
              :rules="[rules.required]"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" lg="4" sm="4" md="4"></v-col>
      <div class="form-group">
        <div
          style="z-index: 999999"
          class="float-group btn-group-vertical"
          role="group"
          aria-label="Vertical button group"
        >
          <v-btn @click="postSave" color="primary" large class="btn-block">
            Simpan Form
          </v-btn>
          &nbsp;
        </div>
      </div>
    </v-row>
  </div>
</template>
<script>
import {
  // eslint-disable-next-line no-unused-vars
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  errorMsg,
  // eslint-disable-next-line no-unused-vars
  setBtnLoading,
  // eslint-disable-next-line no-unused-vars
  resetBtnLoading
} from '../../plugins/supports'
// import { use } from 'vue/types/umd'
// import { apiKedua } from '../plugins/supports'
// import { apiKedua } from '../plugins/supports'
// import { isEmpty, jsonPrettier } from '@/plugins/supports'
import Master from '../../data/laporan-tindakan-operasi'
import SmartWidget from '@/components/SmartWidget.vue'
import Dialog from '@/components/Dialog'
import SekalaNyeri from '@/components/SekalaNyeri.vue'
export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    DialogConfirm: Dialog,
    // eslint-disable-next-line vue/no-unused-components
    SekalaNyeri,
    // eslint-disable-next-line vue/no-unused-components
    SmartWidget
    // DialogConfirm: Dialog,
    // Soap,
  },
  data () {
    return {
      user: JSON.parse(localStorage.getItem('user')),
      item_ke: null,
      url_emr: 'http://emr.rs-syafira.com/',
      url: '',
      site: '',
      history: [],
      patient_name: '',
      tabKiri: '',
      tabKanan: 0,
      search: '',
      rules: {
        required: (value) => {
          // return !isEmpty(value) || 'Tidak Boleh Kosong'
          return true
        },
        dialogShow: false,
        dialogMessage: 'Simpan Form Sekarang?',
        dialogProgress: false,
        dialogDisableNBtn: false,
        dialogDisablePBtn: false
      },
      master: Master,
      data: {
        demografi: {
          nama_pasien: '',
          no_rekam_medis: '',
          tanggal_lahir: '',
          cara_bayar: '',
          ruangan: '',
          kamar_kelas: '',
          jenis_kelamin: '',
          folio_id: '',
          sub_unit_id: '',
          reg_id: ''
        },
        form1: {
          nama_dokter: '',
          nama_ahli_anestesi: '',
          nama_perawat: '',
          nama_asisten: ''
          // tiba_diruangan_tanggal: '',
          // tiba_diruangan_jam: '',
          // pengkajian_tanggal: '',
          // pengkajian_jam: '',
          // cara_masuk: '',
          // asal_pasien: '',
          // masuk_kasus_trauma: '',
          // hasil_yang_dibawa: ''
        }
      }
    }
  },
  created () {
    if (this.$route.query.no_rm != null) {
      this.getDataPasien(this.$route.query.folio_id)
      this.getAssemen(this.$route.query.folio_id)
    }
  },

  methods: {
    postSave () {
      // const formData = new FormData()
      // formData.append(
      //   'data',
      //   new Blob([JSON.stringify(this.data)], { type: 'application/json' })
      // )
      // console.log(JSON.parse(JSON.stringify(this.data)))

      // console.log(this.data)
      // eslint-disable-next-line no-unused-vars
      var data = {
        rm: this.$route.query.no_rm,
        folio_id: this.$route.query.folio_id,
        regis_id: this.$route.query.registration_id,
        sub_unit: this.$route.query.sub_unit_id,
        data: this.data,
        jenis: 'rawat-inap',
        created_by: this.user.employee_id,

        type: 'Laporan Tindakan Operasi'
      }
      // eslint-disable-next-line no-undef
      $.post(
        baseUrl + 'rawat-inap/simpan-form-tindakan',
        data,
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            successMsg(msg)
          } else {
            errorMsg(msg)
          }
        },
        'JSON'
      )
    },
    getDataPasien (folioId) {
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'rawat-inap/get-detail-pasien',
        { folio_id: folioId },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            this.data.demografi = results
            this.data.form1.nama_dokter = results.dpjp_utama
            this.data.demografi.jenis_kelamin = {
              value: results.jenis_kelamin,
              text: results.jenis_kelamin
            }
          } else {
          }
        },
        'JSON'
      )
    },
    getAssemen (folioId) {
      var type = 'Laporan Tindakan Operasi'
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'rawat-inap/get-form-tindakan',
        { folio_id: folioId, jenis: type },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            this.data = results
          } else {
            this.data.form1.p1 = this.data.demografi.nama_pasien
            this.data.form1.p2 = this.data.demografi.no_rekam_medis
            this.data.form1.p3 = this.data.demografi.cara_bayar
            this.data.form1.p4 =
              this.data.demografi.kamar_kelas +
              '-' +
              this.data.demografi.ruangan
            // this.data.form4 = results.form4
            // this.data =
          }
        },
        'JSON'
      )
    }
  }
}
</script>
<style scoped>
.float-group {
  position: fixed;
  bottom: 5%;
  right: 40px;
  color: #fff;
  text-align: center;
  z-index: 100;
}

.float {
  position: fixed;
  bottom: 160px;
  right: 40px;
  color: #fff;
  text-align: center;
  z-index: 100;
}
</style>
