export default {
  demografi: {
    nama_pasien: {
      label: 'Nama Pasien',
      widget: 'wtext',
      data: null,
      disabled: true,
      col: 3
    },
    no_rekam_medis: {
      label: 'No Rekam Medis',
      widget: 'wtext',
      disabled: true,

      data: null,
      col: 3
    },
    tanggal_lahir: {
      label: 'Tangga Lahir',
      widget: 'wdatenormal',
      data: null,
      disabled: true,

      col: 3
    },
    cara_bayar: {
      label: 'Cara Bayar',
      widget: 'wtext',
      disabled: true,

      data: [],
      col: 3
    },
    ruangan: {
      widget: 'wtext',
      data: null,
      label: 'Ruangan',
      disabled: true,

      col: 3
    },
    kamar_kelas: {
      widget: 'wtext',
      data: null,
      label: 'Kamar Kelas',
      disabled: true,

      col: 3
    },
    jenis_kelamin: {
      widget: 'wradio',
      disabled: true,

      data: [
        { text: 'Laki-Laki', value: 'laki-laki' },
        { text: 'Wanita', value: 'wanita' }
      ],
      col: 6
    },
    dpjp_utama: {
      widget: 'wtext',
      data: null,
      disabled: true,

      label: 'Dokter DPJP Utama',
      col: 6
    },
    dpjp_pembantu: {
      widget: 'wtext',
      disabled: true,

      data: null,
      label: 'Dokter DPJP',
      col: 6
    },
    folio_id: {
      widget: 'wtext',
      data: null,
      disabled: true,

      col: 4,
      label: 'No Folio'
    },
    sub_unit_id: {
      widget: 'wtext',
      disabled: true,

      data: null,
      col: 2,
      label: 'Sub Unit Dokter'
    },
    reg_id: {
      widget: 'wtext',
      disabled: true,

      data: null,
      label: 'No Reg',
      col: 4
    }
  },
  form1: {
    label1: {
      label: 'Tanggal Pemeriksaan',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    tgl_pemeriksaan: {
      label: ' ',
      widget: 'wdatenormal',
      data: [],
      col: 9
    },
    label2: {
      label: 'Keluhan Utama',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    keluhan_utama: {
      widget: 'wtextarea',
      data: [],
      col: 9,
      label: ' '
    },
    label3: {
      label: 'a. Keadaan Umum',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    gcs: {
      widget: 'wtext',
      data: [],
      col: 3,
      label: 'GCS'
    },
    vital_sign: {
      widget: 'wtext',
      data: [],
      col: 3,
      label: 'Vital Sign'
    },
    td: {
      widget: 'wtext',
      data: [],
      col: 3,
      label: 'Tekanan Darah'
    },
    label4: {
      label: ' ',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    n: {
      widget: 'wtext',
      data: [],
      col: 3,
      label: 'N'
    },
    RR: {
      widget: 'wtext',
      data: [],
      col: 3,
      label: 'RR'
    },
    suhu: {
      widget: 'wtext',
      data: [],
      col: 3,
      label: 'Suhu'
    },
    label5: {
      label: 'b. Pemeriksaan Regional',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    pemeriksaan_regional: {
      widget: 'wtextarea',
      data: [],
      col: 9,
      label: ' '
    },
    label6: {
      label: 'Asesmen',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    asesmen: {
      widget: 'wtextarea',
      data: [],
      col: 9,
      label: ' '
    },
    label7: {
      label: 'Pemeriksaan Penunjang',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    pemeriksaan_penunjang: {
      widget: 'wcheckbox',
      label: ' ',
      col: 9,
      data: [
        {
          text: 'ECG',
          value: 'ECG'
        },
        {
          text: 'LAB',
          value: 'LAB'
        },
        {
          text: 'RONTAGEN',
          value: 'RONTAGEN'
        },
        {
          text: 'CT SCAN IN',
          value: 'CT SCAN IN'
        },
        {
          text: 'MRI',
          value: 'MRI'
        },
        {
          text: 'Lain-Lain, Jelaskan',
          value: null,
          field: 'auto'
        }
      ]
    },
    label8: {
      label: 'Diagnosa Pra Bedah',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    diagnosa_pra_bedah: {
      widget: 'wtextarea',
      data: [],
      col: 9,
      label: ' '
    },
    label9: {
      label: 'Planning: Th/DX',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    planning: {
      widget: 'wtextarea',
      data: [],
      col: 9,
      label: ' '
    },
    label10: {
      label: 'Penggunaan Antibiotic Sebagai Terapi dan Profilaksis Pembedahaan',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    penggunaan: {
      widget: 'wradio',
      data: [
        {
          text: 'Tidak',
          value: 'Tidak'
        },
        {
          text: 'Ya, Jelaskan',
          value: null,
          field: 'auto'
        }
      ],
      col: 9,
      label: ' '
    },
    label11: {
      label: 'Alat Khusus',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    alat_khusus: {
      widget: 'wradio',
      data: [
        {
          text: 'Tidak',
          value: 'Tidak'
        },
        {
          text: 'Ya, Jelaskan',
          value: null,
          field: 'auto'
        }
      ],
      col: 9,
      label: ' '
    },
    label12: {
      label: 'Informed Consent',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    informed_consent: {
      widget: 'wradio',
      data: [
        {
          text: 'Tidak',
          value: 'Tidak'
        },
        {
          text: 'Ya, Jelaskan',
          value: null,
          field: 'auto'
        }
      ],
      col: 9,
      label: ' '
    }
  }
}
