export default {
  demografi: {
    nama_pasien: {
      label: 'Nama Pasien',
      widget: 'wtext',
      data: null,
      disabled: true,
      col: 3
    },
    no_rekam_medis: {
      label: 'No Rekam Medis',
      widget: 'wtext',
      disabled: true,

      data: null,
      col: 3
    },
    tanggal_lahir: {
      label: 'Tangga Lahir',
      widget: 'wdatenormal',
      data: null,
      disabled: true,

      col: 3
    },
    cara_bayar: {
      label: 'Cara Bayar',
      widget: 'wtext',
      disabled: true,

      data: [],
      col: 3
    },
    ruangan: {
      widget: 'wtext',
      data: null,
      label: 'Ruangan',
      disabled: true,

      col: 3
    },
    kamar_kelas: {
      widget: 'wtext',
      data: null,
      label: 'Kamar Kelas',
      disabled: true,

      col: 3
    },
    jenis_kelamin: {
      widget: 'wradio',
      disabled: true,

      data: [
        { text: 'Laki-Laki', value: 'laki-laki' },
        { text: 'Wanita', value: 'wanita' }
      ],
      col: 6
    },
    dpjp_utama: {
      widget: 'wtext',
      data: null,
      disabled: true,

      label: 'Dokter DPJP Utama',
      col: 6
    },
    dpjp_pembantu: {
      widget: 'wtext',
      disabled: true,

      data: null,
      label: 'Dokter DPJP',
      col: 6
    },
    folio_id: {
      widget: 'wtext',
      data: null,
      disabled: true,

      col: 4,
      label: 'No Folio'
    },
    sub_unit_id: {
      widget: 'wtext',
      disabled: true,

      data: null,
      col: 2,
      label: 'Sub Unit Dokter'
    },
    reg_id: {
      widget: 'wtext',
      disabled: true,

      data: null,
      label: 'No Reg',
      col: 6
    }

  },
  form1: {
    label1: {
      label: 'Nama Dokter',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    nama_dokter: {
      label: ' ',
      widget: 'wtext',
      data: [],
      disabled: true,
      col: 3
    },
    label2: {
      label: 'Nama Ahli Anestesi',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    nama_ahli_anestesi: {
      widget: 'wtext',
      data: [],
      col: 3,
      label: ' '
    },
    label3: {
      label: 'Nama Perawat Instrumen',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    nama_perawat: {
      widget: 'wtext',
      data: [],
      col: 3,
      label: ' '
    },
    label4: {
      label: 'Nama Asisten',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    nama_asisten: {
      widget: 'wtext',
      data: [],
      col: 3,
      label: ' '
    },
    label5: {
      label: 'Diagnosa Pra Operasi',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    diagnosa_pra_operasi: {
      widget: 'wtext',
      data: [],
      col: 9,
      label: ' '
    },
    label6: {
      label: 'Diagnosa Pasca Operasi',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    diagnosa_pasca_operasi: {
      widget: 'wtext',
      data: [],
      col: 9,
      label: ' '
    },
    label7: {
      label: 'Nama/Tindakan Operasi',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    nama_tindakan_operasi: {
      widget: 'wtext',
      data: [],
      col: 9,
      label: ' '
    },
    label8: {
      label: 'Tindakan Pembedahaan',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    tindakan_pembedahaan_1: {
      widget: 'wtext',
      data: [],
      col: 3,
      label: '1.'
    },
    tindakan_pembedahaan_2: {
      widget: 'wtext',
      data: [],
      col: 3,
      label: '2.'
    },
    tindakan_pembedahaan_3: {
      widget: 'wtext',
      data: [],
      col: 3,
      label: '3.'
    },
    label9: {
      label: ' ',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    tindakan_pembedahaan_4: {
      widget: 'wtext',
      data: [],
      col: 3,
      label: '4.'
    },
    label10: {
      label: 'Klasifikasi Operasi',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    klasifikasi_operasi_waktu: {
      widget: 'wradio',
      data: [
        {
          text: 'Emergensi',
          value: 'Emergensi'
        },

        {
          text: 'Elektif',
          value: 'Elektif'
        }
      ],
      col: 3,
      label: ' '
    },
    label11: {
      label: 'Golongan Operasi',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    golongan_operasi: {
      widget: 'wradio',
      data: [
        {
          text: 'Canggih',
          value: 'Canggih'
        },
        {
          text: 'Khusus',
          value: 'Khusus'
        },
        {
          text: 'Besar',
          value: 'Besar'
        },
        {
          text: 'Sedang',
          value: 'Sedang'
        },
        {
          text: 'Kecil',
          value: 'Kecil'
        }
      ],
      col: 9,
      label: ' '
    },
    label12: {
      label: 'Jaringan yang Di eksisi/insisi: ',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    jaringan_yang_di_eksisi: {
      widget: 'wtext',
      data: [],
      col: 3,
      label: ' '
    },
    label13: {
      label: 'Dikirim Untuk Pemeriksaan Patalogi',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    dikirim_untuk_pemeriksaan_patalogi: {
      widget: 'wradio',
      data: [
        {
          text: 'Ya',
          value: 'Ya'
        },
        {
          text: 'Tidak',
          value: 'Tidak'
        }
      ],
      col: 3,
      label: ' '
    },
    label14: {
      label: 'Dikonsulkan diatas Meja Operasi',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    dikonsulkan: {
      widget: 'wradio',
      data: [
        {
          text: 'Ya, Ke Dokter',
          value: null,
          field: 'auto'
        },
        {
          text: 'Tidak',
          value: 'Tidak'
        }
      ],
      col: 3,
      label: ' '
    },
    label15: {
      label: 'Komplikasi',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    komplikasi: {
      widget: 'wradio',
      data: [
        {
          text: 'Ya,',
          value: null,
          field: 'auto'
        },
        {
          text: 'Tidak',
          value: 'Tidak'
        }
      ],
      col: 3,
      label: ' '
    },
    tanggal_tindakan: {
      widget: 'wdatenormal',
      data: null,
      label: 'Tanggal Tindakan Operasi',
      col: 3
    },
    jam_dimulai: {
      label: 'Jam Dimulai Operasi',
      widget: 'wtime',
      data: [],
      col: 3
    },
    jam_selesai: {
      label: 'Jam Selesai Operasi',
      widget: 'wtime',
      data: [],
      col: 3
    },
    lama_tindakan: {
      label: 'Lama Tindakan Operasi',
      widget: 'wtext',
      data: [],
      col: 3
    },
    nomor_alat: {
      widget: 'wtextarea',
      data: null,
      label: 'Nomor Alat',
      col: 12
    },
    laporan_tindakan: {
      widget: 'wtextarea',
      data: null,
      label: 'Laporan Tindakan',
      col: 12
    }
  }
}
