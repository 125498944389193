<template>
  <div>
    <svg
      width="275.69583mm"
      height="87.841667mm"
      viewBox="0 0 275.69583 87.841667"
      version="1.1"
      id="svg5"
      sodipodi:docname="skala_nyeri.svg"
      inkscape:version="1.1 (c68e22c387, 2021-05-23)"
      xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
      xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:svg="http://www.w3.org/2000/svg"
      style="width: 100%; height: 230px"
    >
      <sodipodi:namedview
        id="namedview7"
        pagecolor="#ffffff"
        bordercolor="#666666"
        borderopacity="1.0"
        inkscape:pageshadow="2"
        inkscape:pageopacity="0.0"
        inkscape:pagecheckerboard="0"
        inkscape:document-units="mm"
        showgrid="false"
        inkscape:snap-global="true"
        showguides="false"
        inkscape:zoom="0.70710678"
        inkscape:cx="582.65599"
        inkscape:cy="105.35891"
        inkscape:window-width="1366"
        inkscape:window-height="705"
        inkscape:window-x="-8"
        inkscape:window-y="-8"
        inkscape:window-maximized="1"
        inkscape:current-layer="layer1"
      >
        <inkscape:grid type="xygrid" id="grid39051" />
      </sodipodi:namedview>
      <defs id="defs2" />
      <g
        inkscape:label="Layer 1"
        inkscape:groupmode="layer"
        id="layer1"
        transform="translate(53.07336,-48.454197)"
      >
        <ellipse
          :style="`fill: ${fillColorActive[2]};
            fill-opacity: 1;
            fill-rule: evenodd;
            stroke: #333333;
            stroke-width: 0.7;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;`"
          id="o_3"
          class="forclick emoji"
          cx="64.199272"
          cy="108.55707"
          rx="16.253725"
          ry="20.527918"
        />
        <ellipse
          style="
            fill: #ffffff;
            fill-rule: evenodd;
            stroke: #333333;
            stroke-width: 0.7;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          id="path191-8"
          cx="60.094707"
          cy="104.76215"
          rx="4.0643845"
          ry="5.1606073"
        />
        <ellipse
          style="
            fill: #ffffff;
            fill-rule: evenodd;
            stroke: #333333;
            stroke-width: 0.7;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          id="path191-8-8"
          cx="68.735924"
          cy="104.17751"
          rx="4.0643845"
          ry="5.1606073"
        />
        <path
          style="
            fill: none;
            stroke: #000000;
            stroke-width: 0.7;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          d="m 60.258467,111.77799 c 0,0 0.47503,1.05968 1.315466,1.42508 0.840438,0.36541 2.046282,0.51158 2.88672,0.36541 0.840439,-0.14616 1.534713,-0.32887 1.827038,-0.51157 0.292327,-0.18271 0.767357,-0.29232 1.096224,-1.35201"
          id="path2921"
          sodipodi:nodetypes="csssc"
        />
        <path
          style="
            fill: none;
            stroke: #000000;
            stroke-width: 0.7;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          d="m 62.922065,97.894422 c 0,0 -1.043471,-1.163033 -1.883911,-1.528433 -0.84044,-0.36541 -1.477839,-0.408227 -2.318279,-0.262057 -0.84044,0.14616 -1.017946,0.122164 -1.310276,0.304864 -0.29232,0.18271 -1.284114,0.499026 -1.612984,1.558716"
          id="path2921-4"
          sodipodi:nodetypes="csssc"
        />
        <path
          style="
            fill: none;
            stroke: #000000;
            stroke-width: 0.7;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          d="m 72.642864,97.781985 c 0,0 -0.47503,-1.05968 -1.31547,-1.42508 -0.84044,-0.36541 -2.04628,-0.51158 -2.88672,-0.36541 -0.84044,0.14616 -1.53471,0.32887 -1.82704,0.51157 -0.29232,0.18271 -0.76735,0.29232 -1.09622,1.35201"
          id="path2921-4-0"
          sodipodi:nodetypes="csssc"
        />
        <path
          style="
            fill: none;
            stroke: #000000;
            stroke-width: 0.7;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          d="m 57.191742,119.06902 13.745933,-0.31006"
          id="path3207"
        />
        <ellipse
          style="
            fill: #000000;
            fill-rule: evenodd;
            stroke: #333333;
            stroke-width: 0.7;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          id="path191-8-6"
          cx="66.777725"
          cy="104.05701"
          rx="0.95832866"
          ry="1.0210229"
        />
        <ellipse
          style="
            fill: #000000;
            fill-rule: evenodd;
            stroke: #333333;
            stroke-width: 0.7;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          id="path191-8-6-5"
          cx="61.661758"
          cy="104.10868"
          rx="0.95832866"
          ry="1.0210229"
        />
        <ellipse
          :style="`fill: ${fillColorActive[1]};
            fill-opacity: 1;
            fill-rule: evenodd;
            stroke: #333333;
            stroke-width: 0.7;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;`"
          id="o_2"
          class="forclick emoji"
          cx="22.886862"
          cy="108.39886"
          rx="16.253725"
          ry="20.527918"
        />
        <ellipse
          style="
            fill: #ffffff;
            fill-rule: evenodd;
            stroke: #333333;
            stroke-width: 0.7;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          id="path191-8-7"
          cx="18.782291"
          cy="104.60394"
          rx="4.0643845"
          ry="5.1606073"
        />
        <ellipse
          style="
            fill: #ffffff;
            fill-rule: evenodd;
            stroke: #333333;
            stroke-width: 0.7;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          id="path191-8-8-4"
          cx="27.423512"
          cy="104.01929"
          rx="4.0643845"
          ry="5.1606073"
        />
        <path
          style="
            fill: none;
            stroke: #000000;
            stroke-width: 0.7;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          d="m 18.946071,111.61978 c 0,0 0.47503,1.05968 1.315466,1.42508 0.840438,0.36541 2.046282,0.51158 2.88672,0.36541 0.840439,-0.14616 1.534713,-0.32887 1.827038,-0.51157 0.292327,-0.18271 0.767357,-0.29232 1.096224,-1.35201"
          id="path2921-6"
          sodipodi:nodetypes="csssc"
        />
        <path
          style="
            fill: none;
            stroke: #000000;
            stroke-width: 0.7;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          d="m 21.609669,97.736213 c 0,0 -1.043471,-1.163033 -1.883911,-1.528433 -0.84044,-0.36541 -1.477839,-0.408227 -2.318279,-0.262057 -0.84044,0.14616 -1.017946,0.122164 -1.310276,0.304864 -0.29232,0.18271 -1.284114,0.499026 -1.612984,1.558716"
          id="path2921-4-9"
          sodipodi:nodetypes="csssc"
        />
        <path
          style="
            fill: none;
            stroke: #000000;
            stroke-width: 0.7;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          d="m 31.330468,97.623776 c 0,0 -0.47503,-1.05968 -1.31547,-1.42508 -0.84044,-0.36541 -2.04628,-0.51158 -2.88672,-0.36541 -0.84044,0.14616 -1.53471,0.32887 -1.82704,0.51157 -0.29232,0.18271 -0.76735,0.29232 -1.09622,1.35201"
          id="path2921-4-0-2"
          sodipodi:nodetypes="csssc"
        />
        <ellipse
          style="
            fill: #000000;
            fill-rule: evenodd;
            stroke: #333333;
            stroke-width: 0.7;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          id="path191-8-6-8"
          cx="25.465313"
          cy="103.89881"
          rx="0.95832866"
          ry="1.0210229"
        />
        <ellipse
          style="
            fill: #000000;
            fill-rule: evenodd;
            stroke: #333333;
            stroke-width: 0.7;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          id="path191-8-6-5-0"
          cx="20.349346"
          cy="103.95047"
          rx="0.95832866"
          ry="1.0210229"
        />
        <ellipse
          :style="`fill: ${fillColorActive[3]};
            fill-opacity: 1;
            fill-rule: evenodd;
            stroke: #333333;
            stroke-width: 0.7;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;`"
          id="o_4"
          class="forclick emoji"
          cx="105.10203"
          cy="108.52611"
          rx="16.253725"
          ry="20.527918"
        />
        <ellipse
          style="
            fill: #ffffff;
            fill-rule: evenodd;
            stroke: #333333;
            stroke-width: 0.7;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          id="path191-8-0"
          cx="100.99744"
          cy="104.73117"
          rx="4.0643845"
          ry="5.1606073"
        />
        <ellipse
          style="
            fill: #ffffff;
            fill-rule: evenodd;
            stroke: #333333;
            stroke-width: 0.7;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          id="path191-8-8-8"
          cx="109.63866"
          cy="104.14654"
          rx="4.0643845"
          ry="5.1606073"
        />
        <path
          style="
            fill: none;
            stroke: #000000;
            stroke-width: 0.7;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          d="m 101.1612,111.747 c 0,0 0.47503,1.05968 1.31547,1.42508 0.84044,0.36541 2.04628,0.51158 2.88672,0.36541 0.84044,-0.14616 1.53471,-0.32887 1.82704,-0.51157 0.29232,-0.18271 0.76735,-0.29232 1.09622,-1.35201"
          id="path2921-5"
          sodipodi:nodetypes="csssc"
        />
        <path
          style="
            fill: none;
            stroke: #000000;
            stroke-width: 0.7;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          d="m 103.8248,97.863437 c 0,0 -1.04347,-1.16303 -1.88391,-1.52843 -0.84044,-0.36541 -1.47784,-0.40823 -2.318277,-0.26206 -0.84044,0.14616 -1.017946,0.12217 -1.310276,0.30487 -0.29232,0.18271 -1.284114,0.49902 -1.612984,1.55871"
          id="path2921-4-1"
          sodipodi:nodetypes="csssc"
        />
        <path
          style="
            fill: none;
            stroke: #000000;
            stroke-width: 0.7;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          d="m 113.5456,97.751007 c 0,0 -0.47503,-1.05968 -1.31547,-1.42508 -0.84044,-0.36541 -2.04628,-0.51158 -2.88672,-0.36541 -0.84044,0.14616 -1.53471,0.32887 -1.82704,0.51157 -0.29232,0.18271 -0.76735,0.29232 -1.09622,1.35201"
          id="path2921-4-0-3"
          sodipodi:nodetypes="csssc"
        />
        <ellipse
          style="
            fill: #000000;
            fill-rule: evenodd;
            stroke: #333333;
            stroke-width: 0.7;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          id="path191-8-6-1"
          cx="107.68048"
          cy="104.02602"
          rx="0.95832866"
          ry="1.0210229"
        />
        <ellipse
          style="
            fill: #000000;
            fill-rule: evenodd;
            stroke: #333333;
            stroke-width: 0.7;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          id="path191-8-6-5-7"
          cx="102.56451"
          cy="104.07772"
          rx="0.95832866"
          ry="1.0210229"
        />
        <path
          style="
            fill: none;
            stroke: #000000;
            stroke-width: 0.7;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          d="m 97.045985,105.59625 c 0,0 1.281787,-2.66319 2.640117,-3.6236 1.630658,-1.15296 3.338978,-1.40003 3.338978,-1.40003"
          id="path4824"
          sodipodi:nodetypes="csc"
        />
        <path
          style="
            fill: none;
            stroke: #000000;
            stroke-width: 0.7;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          d="m 113.74813,105.49833 c 0,0 -1.457,-2.65681 -3.00103,-3.61492 -1.85356,-1.1502 -3.79541,-1.39668 -3.79541,-1.39668"
          id="path4824-8"
          sodipodi:nodetypes="csc"
        />
        <path
          style="
            fill: none;
            stroke: #000000;
            stroke-width: 0.900277;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          d="m 111.86402,120.47642 c 0,0 -1.72599,-1.16303 -3.11614,-1.52844 -1.39016,-0.3654 -2.44447,-0.40823 -3.83462,-0.26205 -1.39016,0.14616 -1.68376,0.12217 -2.16729,0.30487 -0.48352,0.18271 -2.12404,0.49902 -2.66803,1.5587"
          id="path2921-4-04"
          sodipodi:nodetypes="csssc"
        />
        <ellipse
          :style="`fill: ${fillColorActive[4]};
            fill-opacity: 1;
            fill-rule: evenodd;
            stroke: #333333;
            stroke-width: 0.7;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;`"
          id="o_5"
          class="forclick emoji"
          cx="146.293"
          cy="108.39594"
          rx="16.253725"
          ry="20.527918"
        />
        <ellipse
          style="
            fill: #ffffff;
            fill-rule: evenodd;
            stroke: #333333;
            stroke-width: 0.7;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          id="path191-8-0-0"
          cx="142.1884"
          cy="104.60101"
          rx="4.0643845"
          ry="5.1606073"
        />
        <ellipse
          style="
            fill: #ffffff;
            fill-rule: evenodd;
            stroke: #333333;
            stroke-width: 0.7;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          id="path191-8-8-8-9"
          cx="150.82962"
          cy="104.01637"
          rx="4.0643845"
          ry="5.1606073"
        />
        <path
          style="
            fill: none;
            stroke: #000000;
            stroke-width: 0.7;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          d="m 142.35218,111.61683 c 0,0 0.47503,1.05968 1.31547,1.42508 0.84044,0.36541 2.04628,0.51158 2.88672,0.36541 0.84044,-0.14616 1.53471,-0.32887 1.82704,-0.51157 0.29232,-0.18271 0.76735,-0.29232 1.09622,-1.35201"
          id="path2921-5-1"
          sodipodi:nodetypes="csssc"
        />
        <path
          style="
            fill: none;
            stroke: #000000;
            stroke-width: 0.7;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          d="m 152.335,95.108913 c 0,0 -0.3382,1.110944 0.0553,1.938624 0.39344,0.827688 1.20639,1.730202 1.93598,2.172247 0.72958,0.442049 1.37299,0.760509 1.71341,0.814866 0.34041,0.0543 0.77052,0.28385 1.71473,-0.298877"
          id="path2921-4-0-3-2"
          sodipodi:nodetypes="csssc"
        />
        <path
          style="
            fill: none;
            stroke: #000000;
            stroke-width: 0.697092;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          d="m 140.86651,95.729701 c 0,0 0.3354,1.110944 -0.0548,1.938624 -0.39018,0.827688 -1.19639,1.730202 -1.91993,2.172248 -0.72353,0.442047 -1.36161,0.760507 -1.69921,0.814867 -0.33758,0.0543 -0.76413,0.28385 -1.70051,-0.29888"
          id="path2921-4-0-3-2-0"
          sodipodi:nodetypes="csssc"
        />
        <ellipse
          style="
            fill: #000000;
            fill-rule: evenodd;
            stroke: #333333;
            stroke-width: 0.7;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          id="path191-8-6-1-4"
          cx="148.87144"
          cy="103.89584"
          rx="0.95832866"
          ry="1.0210229"
        />
        <ellipse
          style="
            fill: #000000;
            fill-rule: evenodd;
            stroke: #333333;
            stroke-width: 0.7;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          id="path191-8-6-5-7-1"
          cx="143.75548"
          cy="103.94756"
          rx="0.95832866"
          ry="1.0210229"
        />
        <path
          style="
            fill: none;
            stroke: #000000;
            stroke-width: 0.768394;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          d="m 138.35866,106.69995 c 0,0 1.25037,-3.28966 2.57541,-4.47599 1.59069,-1.42419 3.25714,-1.72937 3.25714,-1.72937"
          id="path4824-2"
          sodipodi:nodetypes="csc"
        />
        <path
          style="
            fill: none;
            stroke: #000000;
            stroke-width: 0.716271;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          d="m 154.62859,105.88439 c 0,0 -1.38765,-2.92077 -2.85819,-3.97408 -1.76534,-1.26448 -3.61476,-1.53544 -3.61476,-1.53544"
          id="path4824-8-7"
          sodipodi:nodetypes="csc"
        />
        <path
          style="
            fill: none;
            stroke: #000000;
            stroke-width: 0.900277;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          d="m 154.2243,122.5387 c 0,0 -1.57982,-1.82077 -2.89689,-2.69775 -1.19642,-0.79664 -3.83302,-1.43137 -5.22317,-1.28519 -1.39016,0.14616 -2.99923,0.7799 -3.48276,0.9626 -0.48352,0.18271 -2.63561,2.25298 -3.1796,3.31266"
          id="path2921-4-04-1"
          sodipodi:nodetypes="csssc"
        />
        <ellipse
          :style="`fill: ${fillColorActive[5]};
            fill-opacity: 1;
            fill-rule: evenodd;
            stroke: #333333;
            stroke-width: 0.7;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;`"
          id="o_6"
          class="forclick emoji"
          cx="188.35892"
          cy="108.26655"
          rx="16.253725"
          ry="20.527918"
        />
        <ellipse
          style="
            fill: #ffffff;
            fill-rule: evenodd;
            stroke: #333333;
            stroke-width: 0.7;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          id="path191-8-0-0-1"
          cx="184.25432"
          cy="104.4716"
          rx="4.0643845"
          ry="5.1606073"
        />
        <ellipse
          style="
            fill: #ffffff;
            fill-rule: evenodd;
            stroke: #333333;
            stroke-width: 0.7;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          id="path191-8-8-8-9-3"
          cx="192.89554"
          cy="103.88698"
          rx="4.0643845"
          ry="5.1606073"
        />
        <path
          style="
            fill: none;
            stroke: #000000;
            stroke-width: 0.7;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          d="m 184.41812,111.48746 c 0,0 0.47503,1.05968 1.31547,1.42508 0.84044,0.36541 2.04628,0.51158 2.88672,0.36541 0.84044,-0.14616 1.5347,-0.32887 1.82704,-0.51157 0.29232,-0.18271 0.76735,-0.29232 1.09622,-1.35201"
          id="path2921-5-1-6"
          sodipodi:nodetypes="csssc"
        />
        <path
          style="
            fill: none;
            stroke: #000000;
            stroke-width: 0.7;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          d="m 193.87177,96.567041 c 0,0 -0.3382,1.11095 0.0553,1.93863 0.39344,0.82769 1.20639,1.730199 1.93598,2.172249 0.72958,0.44206 1.37299,0.76052 1.71342,0.81488 0.3404,0.0543 0.77052,0.28385 1.71473,-0.29888"
          id="path2921-4-0-3-2-8"
          sodipodi:nodetypes="csssc"
        />
        <path
          style="
            fill: none;
            stroke: #000000;
            stroke-width: 0.697092;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          d="m 183.46162,96.658661 c 0,0 0.33539,1.11095 -0.0548,1.93863 -0.39018,0.82769 -1.1964,1.730199 -1.91993,2.172249 -0.72354,0.44206 -1.36162,0.76052 -1.69921,0.81488 -0.33758,0.0543 -0.76413,0.28384 -1.70051,-0.29888"
          id="path2921-4-0-3-2-0-7"
          sodipodi:nodetypes="csssc"
        />
        <ellipse
          style="
            fill: #000000;
            fill-rule: evenodd;
            stroke: #333333;
            stroke-width: 0.7;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          id="path191-8-6-1-4-1"
          cx="190.93736"
          cy="106.4123"
          rx="0.95832866"
          ry="1.0210229"
        />
        <ellipse
          style="
            fill: #000000;
            fill-rule: evenodd;
            stroke: #333333;
            stroke-width: 0.7;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          id="path191-8-6-5-7-1-1"
          cx="185.8214"
          cy="106.46397"
          rx="0.95832866"
          ry="1.0210229"
        />
        <path
          style="
            fill: none;
            stroke: #000000;
            stroke-width: 0.74561;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          d="m 180.84001,107.02978 c 0,0 1.39425,-2.77784 2.87176,-3.77957 1.77373,-1.20261 3.63191,-1.4603 3.63191,-1.4603"
          id="path4824-2-1"
          sodipodi:nodetypes="csc"
        />
        <path
          style="
            fill: none;
            stroke: #000000;
            stroke-width: 0.729002;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          d="m 196.69566,106.83287 c 0,0 -1.54241,-2.72195 -3.17696,-3.70354 -1.96223,-1.17841 -4.01792,-1.43092 -4.01792,-1.43092"
          id="path4824-8-7-0"
          sodipodi:nodetypes="csc"
        />
        <path
          style="
            fill: none;
            stroke: #000000;
            stroke-width: 0.803529;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          d="m 194.71846,122.42513 c 0,0 -1.00471,-2.9339 -2.1344,-3.70114 -1.41063,-0.95804 -2.15076,-0.40928 -3.81785,-0.46864 -1.10778,-0.0394 -1.88552,-0.20961 -3.16587,0.40185 -1.15271,0.55051 -2.1346,2.99445 -2.56453,4.06259"
          id="path2921-4-04-1-3"
          sodipodi:nodetypes="csssc"
        />
        <path
          style="
            fill: none;
            stroke: #000000;
            stroke-width: 0.900277;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          d="m 14.842859,118.89307 c 0,0 1.57982,1.82077 2.89689,2.69775 1.19642,0.79664 3.83302,1.43137 5.22317,1.28519 1.39016,-0.14616 2.99923,-0.7799 3.48276,-0.9626 0.48352,-0.18271 2.63561,-2.25298 3.1796,-3.31266"
          id="path2921-4-04-1-5"
          sodipodi:nodetypes="csssc"
        />
        <ellipse
          :style="`fill: ${fillColorActive[0]};
            fill-rule: evenodd;
            stroke: #333333;
            stroke-width: 0.7;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;`"
          id="o_1"
          class="forclick emoji"
          cx="-20.504805"
          cy="108.39886"
          rx="16.253725"
          ry="20.527918"
        />
        <ellipse
          style="
            fill: #ffffff;
            fill-rule: evenodd;
            stroke: #333333;
            stroke-width: 0.7;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          id="path191-8-7-0"
          cx="-24.609373"
          cy="104.60394"
          rx="4.0643845"
          ry="5.1606073"
        />
        <ellipse
          style="
            fill: #ffffff;
            fill-rule: evenodd;
            stroke: #333333;
            stroke-width: 0.7;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          id="path191-8-8-4-1"
          cx="-15.968154"
          cy="104.01929"
          rx="4.0643845"
          ry="5.1606073"
        />
        <path
          style="
            fill: none;
            stroke: #000000;
            stroke-width: 0.7;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          d="m -24.445611,111.61978 c 0,0 0.47503,1.05969 1.315466,1.42508 0.840438,0.36541 2.046282,0.51158 2.88672,0.36541 0.840439,-0.14616 1.534713,-0.32887 1.827038,-0.51157 0.292327,-0.18271 0.767357,-0.29232 1.096224,-1.35201"
          id="path2921-6-9"
          sodipodi:nodetypes="csssc"
        />
        <path
          style="
            fill: none;
            stroke: #000000;
            stroke-width: 0.704514;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          d="m -30.096705,96.986038 c 0,0 0.271307,-0.877597 0.864515,-1.660943 0.571236,-0.75433 0.868135,-1.138401 1.655895,-1.60998 0.793767,-0.475174 2.069267,-0.712017 2.920581,-0.565847 0.851313,0.14616 1.451213,0.458061 1.747324,0.640761 0.296102,0.18271 0.921845,0.640756 1.435838,1.442063"
          id="path2921-4-0-2-4-5"
          sodipodi:nodetypes="cssssc"
        />
        <path
          style="
            fill: none;
            stroke: #000000;
            stroke-width: 0.704514;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          d="m -10.108979,96.798307 c 0,0 -0.271307,-0.877597 -0.864515,-1.660943 -0.571236,-0.75433 -0.868135,-1.138401 -1.655895,-1.60998 -0.793767,-0.475174 -2.069267,-0.712017 -2.920581,-0.565847 -0.851313,0.14616 -1.451213,0.458061 -1.747324,0.640761 -0.296102,0.18271 -0.921845,0.640756 -1.435838,1.442063"
          id="path2921-4-0-2-4-5-3"
          sodipodi:nodetypes="cssssc"
        />
        <ellipse
          style="
            fill: #000000;
            fill-rule: evenodd;
            stroke: #333333;
            stroke-width: 0.7;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          id="path191-8-6-8-9"
          cx="-17.926352"
          cy="103.89881"
          rx="0.95832866"
          ry="1.0210229"
        />
        <ellipse
          style="
            fill: #000000;
            fill-rule: evenodd;
            stroke: #333333;
            stroke-width: 0.7;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          id="path191-8-6-5-0-4"
          cx="-23.042318"
          cy="103.95047"
          rx="0.95832866"
          ry="1.0210229"
        />
        <path
          style="
            fill: none;
            stroke: #000000;
            stroke-width: 0.7;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          d="m -31.402078,114.62436 c 0,0 2.209755,3.60895 4.051992,5.34721 1.67348,1.57901 5.361395,2.83708 7.305852,2.54736 1.944471,-0.28972 4.19514,-1.54583 4.871472,-1.90798 0.676318,-0.36213 3.686531,-4.46559 4.44743,-6.56596"
          id="path2921-4-04-1-5-0"
          sodipodi:nodetypes="csssc"
        />
        <rect
          style="
            fill: #ffffff;
            fill-opacity: 1;
            stroke: #333333;
            stroke-width: 0.7;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          id="kotak_tidak_nyeri"
          width="37.620441"
          height="17.776693"
          x="-40.352669"
          y="67.896912"
        />
        <text
          xml:space="preserve"
          style="
            font-style: normal;
            font-weight: normal;
            font-size: 10.5833px;
            line-height: 1.25;
            font-family: sans-serif;
            fill: #000000;
            fill-opacity: 1;
            stroke: none;
            stroke-width: 0.264583;
          "
          x="-34.676552"
          y="75.532646"
          id="text12589"
        >
          <tspan
            sodipodi:role="line"
            id="tspan12587"
            style="stroke-width: 0.264583"
            x="-34.676552"
            y="75.532646"
          />
        </text>
        <text
          xml:space="preserve"
          style="
            font-style: normal;
            font-weight: normal;
            font-size: 5.39797px;
            line-height: 1.25;
            font-family: sans-serif;
            fill: #000000;
            fill-opacity: 1;
            stroke: none;
            stroke-width: 0.134949;
          "
          x="-45.165062"
          y="67.326996"
          id="text18147"
          transform="scale(0.8542846,1.1705701)"
        >
          <tspan
            sodipodi:role="line"
            id="tspan18145"
            style="font-weight: bold; stroke-width: 0.134949"
            x="-45.165062"
            y="67.326996"
          >
            TIDAK NYERI
          </tspan>
        </text>
        <rect
          style="
            fill: #ffffff;
            fill-opacity: 1;
            stroke: #333333;
            stroke-width: 0.675245;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          id="kotak_ringan"
          width="34.958023"
          height="17.801447"
          x="5.2052798"
          y="67.872162"
        />
        <text
          xml:space="preserve"
          style="
            font-style: normal;
            font-weight: normal;
            font-size: 5.74695px;
            line-height: 1.25;
            font-family: sans-serif;
            fill: #000000;
            fill-opacity: 1;
            stroke: none;
            stroke-width: 0.143674;
          "
          x="12.106334"
          y="71.571342"
          id="text18147-6"
          transform="scale(0.90824953,1.101019)"
        >
          <tspan
            sodipodi:role="line"
            id="tspan18145-6"
            style="font-weight: bold; stroke-width: 0.143674"
            x="12.106334"
            y="71.571342"
          >
            RINGAN
          </tspan>
        </text>
        <rect
          style="
            fill: #ffffff;
            fill-opacity: 1;
            stroke: #333333;
            stroke-width: 0.675245;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          id="kotak_sedang"
          width="34.958023"
          height="17.801447"
          x="67.679993"
          y="67.872162"
        />
        <text
          xml:space="preserve"
          style="
            font-style: normal;
            font-weight: normal;
            font-size: 5.74695px;
            line-height: 1.25;
            font-family: sans-serif;
            fill: #000000;
            fill-opacity: 1;
            stroke: none;
            stroke-width: 0.143674;
          "
          x="80.892265"
          y="71.571342"
          id="text18147-6-5"
          transform="scale(0.90824953,1.101019)"
        >
          <tspan
            sodipodi:role="line"
            id="tspan18145-6-1"
            style="font-weight: bold; stroke-width: 0.143674"
            x="80.892265"
            y="71.571342"
          >
            SEDANG
          </tspan>
        </text>
        <rect
          style="
            fill: #ffffff;
            fill-opacity: 1;
            stroke: #333333;
            stroke-width: 0.675245;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          id="kotak_berat"
          width="34.958023"
          height="17.801447"
          x="126.97969"
          y="67.872162"
        />
        <text
          xml:space="preserve"
          style="
            font-style: normal;
            font-weight: normal;
            font-size: 5.74695px;
            line-height: 1.25;
            font-family: sans-serif;
            fill: #000000;
            fill-opacity: 1;
            stroke: none;
            stroke-width: 0.143674;
          "
          x="148.51306"
          y="71.571335"
          id="text18147-6-5-1"
          transform="scale(0.90824953,1.101019)"
        >
          <tspan
            sodipodi:role="line"
            id="tspan18145-6-1-2"
            style="font-weight: bold; stroke-width: 0.143674"
            x="148.51306"
            y="71.571335"
          >
            BERAT
          </tspan>
        </text>
        <rect
          style="
            fill: #ffffff;
            fill-opacity: 1;
            stroke: #333333;
            stroke-width: 0.675245;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          id="kotak_berat_sekali"
          width="34.958023"
          height="17.801447"
          x="170.93343"
          y="67.872162"
        />
        <text
          xml:space="preserve"
          style="
            font-style: normal;
            font-weight: normal;
            font-size: 5.03298px;
            line-height: 1.25;
            font-family: sans-serif;
            fill: #000000;
            fill-opacity: 1;
            stroke: none;
            stroke-width: 0.125825;
          "
          x="216.04424"
          y="62.679619"
          id="text18147-6-5-1-4"
          transform="scale(0.79541259,1.2572092)"
        >
          <tspan
            sodipodi:role="line"
            id="tspan18145-6-1-2-8"
            style="font-weight: bold; stroke-width: 0.125825"
            x="216.04424"
            y="62.679619"
          >
            BERAT SEKALI
          </tspan>
        </text>
        <rect
          :style="`
            fill: ${fillTabActive[0]};
            fill-opacity: 1;
            stroke: #333333;
            stroke-width: 0.704327;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;`
          "
          id="kotak-0"
          @click="kotakClicked(0, 0)"
          class="forclick kotak_angka"
          width="20.651464"
          height="10.154001"
          x="-30.698109"
          y="50.081753"
        />
        <text
          xml:space="preserve"
          :style="`
            font-style: normal;
            font-weight: normal;
            font-size: 7.77143px;
            line-height: 1.25;
            font-family: sans-serif;
            fill: #000;
            fill-opacity: 1;
            stroke: none;
            stroke-width: 0.194284;`
          "
          x="-22.561029"
          y="58.095066"
          id="text27629"
          transform="scale(1.0089399,0.99113931)"
        >
          <tspan
            sodipodi:role="line"
            id="tspan27627"
            style="stroke-width: 0.194284"
            x="-22.561029"
            y="58.095066"
          >
            0
          </tspan>
        </text>
        <rect
          :style="`
            fill: ${fillTabActive[1]};
            fill-opacity: 1;
            stroke: #333333;
            stroke-width: 0.704327;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;`
          "
          id="kotak-1"
          @click="kotakClicked(1, 1)"
          class="forclick kotak_angka"
          width="20.651464"
          height="10.154001"
          x="-10.046642"
          y="50.081753"
        />
        <text
          xml:space="preserve"
          :style="`
            font-style: normal;
            font-weight: normal;
            font-size: 7.57861px;
            line-height: 1.25;
            font-family: sans-serif;
            fill: #000;
            fill-opacity: 1;
            stroke: none;
            stroke-width: 0.189466;`
          "
          x="-2.0733762"
          y="55.598656"
          id="text27629-8"
          transform="scale(0.96338482,1.0380068)"
        >
          <tspan
            sodipodi:role="line"
            id="tspan27627-9"
            style="stroke-width: 0.189466"
            x="-2.0733762"
            y="55.598656"
          >
            1
          </tspan>
        </text>
        <rect
          :style="`
            fill: ${fillTabActive[2]};
            fill-opacity: 1;
            stroke: #333333;
            stroke-width: 0.704327;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;`
          "
          id="kotak-2"
          @click="kotakClicked(2, 1)"
          class="forclick kotak_angka"
          width="20.651464"
          height="10.154001"
          x="10.604821"
          y="50.081753"
        />
        <text
          xml:space="preserve"
          :style="`
            font-style: normal;
            font-weight: normal;
            font-size: 7.57861px;
            line-height: 1.25;
            font-family: sans-serif;
            fill: #000;
            fill-opacity: 1;
            stroke: none;
            stroke-width: 0.189466;`
          "
          x="19.362988"
          y="55.598656"
          id="text27629-8-3"
          transform="scale(0.96338482,1.0380068)"
        >
          <tspan
            sodipodi:role="line"
            id="tspan27627-9-3"
            style="stroke-width: 0.189466"
            x="19.362988"
            y="55.598656"
          >
            2
          </tspan>
        </text>
        <rect
          :style="`
            fill: ${fillTabActive[3]};
            fill-opacity: 1;
            stroke: #333333;
            stroke-width: 0.704327;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;`
          "
          id="kotak-3"
          @click="kotakClicked(3, 1)"
          class="forclick kotak_angka"
          width="20.651464"
          height="10.154001"
          x="31.256287"
          y="50.081753"
        />
        <text
          xml:space="preserve"
          :style="`
            font-style: normal;
            font-weight: normal;
            font-size: 7.57861px;
            line-height: 1.25;
            font-family: sans-serif;
            fill: #000;
            fill-opacity: 1;
            stroke: none;
            stroke-width: 0.189466;`
          "
          x="40.799351"
          y="55.59866"
          id="text27629-8-3-1"
          transform="scale(0.96338483,1.0380068)"
        >
          <tspan
            sodipodi:role="line"
            id="tspan27627-9-3-3"
            style="stroke-width: 0.189466"
            x="40.799351"
            y="55.59866"
          >
            3
          </tspan>
        </text>
        <rect
          :style="`
            fill: ${fillTabActive[4]};
            fill-opacity: 1;
            stroke: #333333;
            stroke-width: 0.704327;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;`
          "
          id="kotak-4"
          @click="kotakClicked(4, 2)"
          class="forclick kotak_angka"
          width="20.651464"
          height="10.154001"
          x="51.907749"
          y="50.081753"
        />
        <text
          xml:space="preserve"
          :style="`
            font-style: normal;
            font-weight: normal;
            font-size: 7.77143px;
            line-height: 1.25;
            font-family: sans-serif;
            fill: #000;
            fill-opacity: 1;
            stroke: none;
            stroke-width: 0.194284;`
          "
          x="59.312885"
          y="58.09507"
          id="text27629-85"
          transform="scale(1.0089399,0.9911393)"
        >
          <tspan
            sodipodi:role="line"
            id="tspan27627-0"
            style="stroke-width: 0.194284"
            x="59.312885"
            y="58.09507"
          >
            4
          </tspan>
        </text>
        <rect
          :style="`
            fill: ${fillTabActive[5]};
            fill-opacity: 1;
            stroke: #333333;
            stroke-width: 0.704327;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;`
          "
          id="kotak-5"
          @click="kotakClicked(5, 2)"
          class="forclick kotak_angka"
          width="20.651464"
          height="10.154001"
          x="72.559219"
          y="50.081753"
        />
        <text
          xml:space="preserve"
          :style="`
            font-style: normal;
            font-weight: normal;
            font-size: 7.57861px;
            line-height: 1.25;
            font-family: sans-serif;
            fill: #000;
            fill-opacity: 1;
            stroke: none;
            stroke-width: 0.189466;`
          "
          x="83.672058"
          y="55.59866"
          id="text27629-8-9"
          transform="scale(0.96338482,1.0380068)"
        >
          <tspan
            sodipodi:role="line"
            id="tspan27627-9-9"
            style="stroke-width: 0.189466"
            x="83.672058"
            y="55.59866"
          >
            5
          </tspan>
        </text>
        <rect
          :style="`
            fill: ${fillTabActive[6]};
            fill-opacity: 1;
            stroke: #333333;
            stroke-width: 0.704327;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;`
          "
          id="kotak-6"
          @click="kotakClicked(6, 3)"
          class="forclick kotak_angka"
          width="20.651464"
          height="10.154001"
          x="93.210678"
          y="50.081753"
        />
        <text
          xml:space="preserve"
          :style="`
            font-style: normal;
            font-weight: normal;
            font-size: 7.57861px;
            line-height: 1.25;
            font-family: sans-serif;
            fill: #000;
            fill-opacity: 1;
            stroke: none;
            stroke-width: 0.189466;`
          "
          x="105.10843"
          y="55.59866"
          id="text27629-8-3-3"
          transform="scale(0.96338482,1.0380068)"
        >
          <tspan
            sodipodi:role="line"
            id="tspan27627-9-3-9"
            style="stroke-width: 0.189466"
            x="105.10843"
            y="55.59866"
          >
            6
          </tspan>
        </text>
        <rect
          :style="`
            fill: ${fillTabActive[7]};
            fill-opacity: 1;
            stroke: #333333;
            stroke-width: 0.704327;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;`
          "
          id="kotak-7"
          @click="kotakClicked(7, 4)"
          class="forclick kotak_angka"
          width="20.651464"
          height="10.154001"
          x="113.86215"
          y="50.081753"
        />
        <text
          xml:space="preserve"
          :style="`
            font-style: normal;
            font-weight: normal;
            font-size: 7.57861px;
            line-height: 1.25;
            font-family: sans-serif;
            fill: #000;
            fill-opacity: 1;
            stroke: none;
            stroke-width: 0.189466;`
          "
          x="126.54479"
          y="55.59866"
          id="text27629-8-3-1-4"
          transform="scale(0.96338482,1.0380068)"
        >
          <tspan
            sodipodi:role="line"
            id="tspan27627-9-3-3-1"
            style="stroke-width: 0.189466"
            x="126.54479"
            y="55.59866"
          >
            7
          </tspan>
        </text>
        <rect
          :style="`
            fill: ${fillTabActive[8]};
            fill-opacity: 1;
            stroke: #333333;
            stroke-width: 0.704327;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;`
          "
          id="kotak-8"
          @click="kotakClicked(8, 4)"
          class="forclick kotak_angka"
          width="20.651464"
          height="10.154001"
          x="134.51361"
          y="50.081753"
        />
        <text
          xml:space="preserve"
          :style="`
            font-style: normal;
            font-weight: normal;
            font-size: 7.57861px;
            line-height: 1.25;
            font-family: sans-serif;
            fill: #000;
            fill-opacity: 1;
            stroke: none;
            stroke-width: 0.189466;`
          "
          x="147.98114"
          y="55.59866"
          id="text27629-8-9-6"
          transform="scale(0.96338482,1.0380068)"
        >
          <tspan
            sodipodi:role="line"
            id="tspan27627-9-9-3"
            style="stroke-width: 0.189466"
            x="147.98114"
            y="55.59866"
          >
            8
          </tspan>
        </text>
        <rect
          :style="`
            fill: ${fillTabActive[9]};
            fill-opacity: 1;
            stroke: #333333;
            stroke-width: 0.704327;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;`
          "
          id="kotak-9"
          @click="kotakClicked(9, 4)"
          class="forclick kotak_angka"
          width="20.651464"
          height="10.154001"
          x="155.16507"
          y="50.081753"
        />
        <text
          xml:space="preserve"
          :style="`
            font-style: normal;
            font-weight: normal;
            font-size: 7.57861px;
            line-height: 1.25;
            font-family: sans-serif;
            fill: #000;
            fill-opacity: 1;
            stroke: none;
            stroke-width: 0.189466;`
          "
          x="169.41751"
          y="55.59866"
          id="text27629-8-3-3-2"
          transform="scale(0.96338482,1.0380068)"
        >
          <tspan
            sodipodi:role="line"
            id="tspan27627-9-3-9-4"
            style="stroke-width: 0.189466"
            x="169.41751"
            y="55.59866"
          >
            9
          </tspan>
        </text>
        <rect
          :style="`
            fill: ${fillTabActive[10]};
            fill-opacity: 1;
            stroke: #333333;
            stroke-width: 0.704327;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;`
          "
          id="kotak-10"
          @click="kotakClicked(10, 5)"
          class="forclick kotak_angka"
          width="20.651464"
          height="10.154001"
          x="175.81654"
          y="50.081753"
        />
        <text
          xml:space="preserve"
          :style="`
            font-style: normal;
            font-weight: normal;
            font-size: 7.57861px;
            line-height: 1.25;
            font-family: sans-serif;
            fill: #000;
            fill-opacity: 1;
            stroke: none;
            stroke-width: 0.189466;`
          "
          x="188.65672"
          y="55.59866"
          id="text27629-8-3-1-4-3"
          transform="scale(0.96338482,1.0380068)"
        >
          <tspan
            sodipodi:role="line"
            id="tspan27627-9-3-3-1-9"
            style="stroke-width: 0.189466"
            x="188.65672"
            y="55.59866"
          >
            10
          </tspan>
        </text>
        <path
          id="rect37687"
          style="
            fill: #ffffff;
            fill-opacity: 1;
            stroke: #333333;
            stroke-width: 0.7;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          d="m -21.880433,60.371484 v 5.031722 h -1.058333 l 2.359546,2.038462 2.273763,-2.038462 h -1.044898 v -5.031722 z"
        />
        <path
          id="rect37687-0"
          style="
            fill: #ffffff;
            fill-opacity: 1;
            stroke: #333333;
            stroke-width: 0.7;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          d="m 185.15277,60.398102 v 5.031722 h -1.05834 l 2.35955,2.038462 2.27376,-2.038462 h -1.0449 v -5.031722 z"
        />
        <path
          id="path38307-8"
          style="
            fill: none;
            stroke: #000000;
            stroke-width: 0.7;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-opacity: 1;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
          "
          d="m 45.282608,60.29352 c 0,0 -0.198411,1.559518 -0.661368,2.302147 -0.462958,0.742627 -1.322736,1.33673 -2.314789,1.410993 -0.992052,0.07426 -5.489355,-0.07427 -5.489355,-0.07427 L 26.7643,64.00666 c 0,0 -0.727505,0.222788 -1.256599,0.742628 -0.529095,0.519839 -1.142969,0.751096 -1.2566,1.708044 -0.132274,1.113942 -0.132274,1.113942 -0.132274,1.113942 M 2.9550459,60.29352 c 0,0 0.1984105,1.559518 0.6613682,2.302147 0.4629577,0.742627 1.3227363,1.33673 2.3147885,1.410993 0.9920522,0.07426 5.4893554,-0.07427 5.4893554,-0.07427 l 10.052796,0.07427 c 0,0 0.727505,0.222788 1.256599,0.742628 0.529095,0.519839 1.142969,0.751096 1.2566,1.708044 0.132274,1.113942 0.132274,1.113942 0.132274,1.113942"
        />
        <path
          id="path38307-8-4"
          style="
            fill: none;
            stroke: #000000;
            stroke-width: 0.7;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-opacity: 1;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
          "
          d="m 104.87908,60.161544 c 0,0 -0.19841,1.616352 -0.66134,2.386045 -0.46294,0.769691 -1.32268,1.385445 -2.31469,1.462414 -0.99201,0.07696 -5.489114,-0.07697 -5.489114,-0.07697 l -10.052355,0.07697 c 0,0 -0.727473,0.230907 -1.256544,0.769692 -0.529072,0.538784 -1.142918,0.778468 -1.256545,1.770291 -0.132268,1.154538 -0.132268,1.154538 -0.132268,1.154538 M 62.55337,60.161544 c 0,0 0.198402,1.616352 0.661338,2.386045 0.462938,0.769691 1.322679,1.385445 2.314688,1.462414 0.992009,0.07696 5.489116,-0.07697 5.489116,-0.07697 l 10.052355,0.07697 c 0,0 0.727473,0.230907 1.256544,0.769692 0.529072,0.538784 1.142918,0.778468 1.256545,1.770291 0.132268,1.154538 0.132268,1.154538 0.132268,1.154538"
        />
        <path
          id="path38307-8-4-4"
          style="
            fill: none;
            stroke: #000000;
            stroke-width: 0.7;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-opacity: 1;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
          "
          d="m 165.28096,59.992451 c 0,0 -0.19841,1.616352 -0.66134,2.386045 -0.46294,0.769691 -1.32268,1.385445 -2.31469,1.462414 -0.99201,0.07696 -5.48912,-0.07697 -5.48912,-0.07697 l -10.05235,0.07697 c 0,0 -0.72748,0.230907 -1.25655,0.769692 -0.52907,0.538784 -1.14292,0.778468 -1.25654,1.770291 -0.13227,1.154538 -0.13227,1.154538 -0.13227,1.154538 m -21.16286,-7.54298 c 0,0 0.1984,1.616352 0.66134,2.386045 0.46294,0.769691 1.32268,1.385445 2.31469,1.462414 0.99201,0.07696 5.48911,-0.07697 5.48911,-0.07697 l 10.05236,0.07697 c 0,0 0.72747,0.230907 1.25654,0.769692 0.52908,0.538784 1.14292,0.778468 1.25655,1.770291 0.13227,1.154538 0.13227,1.154538 0.13227,1.154538"
        />
      </g>
    </svg>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: [Array, Object, String, Function, Boolean, Number],
      required: false,
      default: () => null
    },
    syncValue: {
      type: Function,
      default: (v) => v,
      required: true
    }
  },
  data () {
    return {
      fillColor: [],
      baseColors: [
        '#00ff00',
        '#d6fb00',
        '#d6fb00',
        '#d6fb00',
        '#ffff00',
        '#ffff00',
        '#ffb900',
        '#ff6600',
        '#ff6600',
        '#ff6600',
        '#ff0000'
      ],
      opacity: 0.7,
      vModel: null,
      tabColor: [],
      side: {
        0: 0,
        1: 1,
        2: 1,
        3: 1,
        4: 2,
        5: 2,
        6: 3,
        7: 4,
        8: 4,
        9: 4,
        10: 5
      }
    }
  },
  created () {
    for (let index = 0; index <= 5; index++) {
      this.fillColor.push('white')
    }
    for (let index = 0; index <= 10; index++) {
      this.tabColor.push('white')
    }
  },
  computed: {
    fillColorActive () {
      // eslint-disable-next-line no-unused-vars
      const tmp = this.vModel
      return this.fillColor
    },
    fillTabActive () {
      // eslint-disable-next-line no-unused-vars
      const tmp = this.vModel
      return this.tabColor
    }
  },
  watch: {
    vModel (n, o) {
      this.syncValue(n)
    },
    value (n, o) {
      this.vModel = n
      this.kotakClicked(n, this.side[n])
    }
  },
  methods: {
    kotakClicked (v, f) {
      for (let index = 0; index <= 5; index++) {
        this.fillColor[index] = 'white'
      }
      for (let index = 0; index <= 10; index++) {
        this.tabColor[index] = 'white'
      }
      this.vModel = v
      this.fillColor[f] = this.baseColors[v]
      this.tabColor[v] = this.baseColors[v]
    }
  }
}
</script>
<style scoped>
.forclick:focus {
  opacity: 0.7 !important;
  fill: rgb(216, 182, 255) !important;
  cursor: pointer;
}
svg >>> text {
  pointer-events: none !important;
}
</style>
