<template>
  <v-container fluid class="mt-3">
    <h3 class="mb-3 mt-1">Kamar Operasi</h3>
    <v-tabs
      show-arrows
      scrollable
      v-model="tab"
      @change="setTab"
      dense
      centered
      class="mt-2"
    >
      <v-tab @click="tab = 0">Asesmen Pra Bedah</v-tab>
      <v-tab @click="tab = 1">FORM FTO</v-tab>
      <v-tab @click="tab = 2">Laporan Tindakan Operasi</v-tab>
      <v-tab @click="tab = 3">Pre-Operasi dan Post Operasi</v-tab>
    </v-tabs>
    <div :hidden="tab !== 0">
      <AsesmenPraBedah />
    </div>
    <div :hidden="tab !== 1">
      <FormTindakanOeprasi />
    </div>
    <div :hidden="tab !== 2">
      <LaporanTindakanOperasi />
    </div>
    <div :hidden="tab !== 3">
      <SerahTerimaOperasi />
    </div>

    <!-- <v-row justify="center"> -->
    <!-- <v-col cols="12" lg="4" sm="4" md="4">
        <v-select
          v-model="value"
          :items="items"
          outlined
          label="Pilih Modul Bedah"
        ></v-select>
      </v-col> -->
    <!-- <v-col
        cols="12"
        lg="12"
        sm="12"
        class=""
        md="12"
        v-if="value == 'Asesmen Pra Bedah'"
      >
        <AsesmenPraBedah />
      </v-col>
      <v-col
        cols="12"
        lg="12"
        sm="12"
        class=""
        md="12"
        v-if="value == 'Formulir Penandaan Area Operasi'"
      >
        <FormulirPenandaanAreaOperasi />
      </v-col>
      <v-col
        cols="12"
        lg="12"
        sm="12"
        class=""
        md="12"
        v-if="value == 'Laporan Tindakan Operasi'"
      >
        <LaporanTindakanOperasi />
      </v-col>
      <v-col
        cols="12"
        lg="12"
        sm="12"
        class=""
        md="12"
        v-if="value == 'Serah Terima Pre-Operasi dan Post Operasi'"
      >
        <SerahTerimaOperasi />
      </v-col>
      <v-col
        cols="12"
        lg="12"
        sm="12"
        class=""
        md="12"
        v-if="value == 'Form Tindakan Operasi'"
      >
        <FormTindakanOeprasi /> -->
    <!-- </v-col> -->
    <!-- </v-row> -->
  </v-container>
</template>
<script>
// import HistoryOrderResep from '@/components/HistoryOrderResep'
// import KedokteranGigi from '@/components/KedokteranGigi'
// import firebase from '@/firebaseInit'
import AsesmenPraBedah from './AsesmenPraBedah'
import FormulirPenandaanAreaOperasi from './FormulirPenandaanAreaOperasi'
import LaporanTindakanOperasi from './LaporanTindakanOperasi'
import SerahTerimaOperasi from './SerahTerimaPreOperasi'
import FormTindakanOeprasi from './FormTindakanOperasi'

import {
  // eslint-disable-next-line no-unused-vars
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  errorMsg
} from '../../plugins/supports'
// import { use } from 'vue/types/umd'
// import { apiKedua } from '../plugins/supports'
// import { apiKedua } from '../plugins/supports'
// import { isEmpty, jsonPrettier } from '@/plugins/supports'
export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    AsesmenPraBedah,
    // eslint-disable-next-line vue/no-unused-components
    FormulirPenandaanAreaOperasi,
    // eslint-disable-next-line vue/no-unused-components
    LaporanTindakanOperasi,
    // eslint-disable-next-line vue/no-unused-components
    SerahTerimaOperasi,
    // eslint-disable-next-line vue/no-unused-components
    FormTindakanOeprasi
    // SmartWidget,
    // SekalaNyeri,
    // DialogConfirm: Dialog,
    // Soap,
  },
  mounted () {},

  data () {
    return {
      tab: '',
      items: [
        'Asesmen Pra Bedah',
        'Laporan Tindakan Operasi',
        'Form Tindakan Operasi',
        'Formulir Penandaan Area Operasi',
        'Serah Terima Pre-Operasi dan Post Operasi'
      ],
      value: 'Asesmen Pra Bedah'
    }
  },
  methods: {
    setTab () {
      // console.log(this.tab)
    }
  }
}
</script>

<style scoped>
.float-group {
  position: fixed;
  bottom: 100px;
  right: 40px;
  color: #fff;
  text-align: center;
  z-index: 100;
}

.float {
  position: fixed;
  bottom: 100px;
  right: 40px;
  color: #fff;
  text-align: center;
  z-index: 100;
}
</style>
