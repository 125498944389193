export default {
  demografi: {
    nama_pasien: {
      label: 'Nama Pasien',
      widget: 'wtext',
      data: null,
      disabled: true,
      col: 3
    },
    no_rekam_medis: {
      label: 'No Rekam Medis',
      widget: 'wtext',
      disabled: true,

      data: null,
      col: 3
    },
    tanggal_lahir: {
      label: 'Tangga Lahir',
      widget: 'wdatenormal',
      data: null,
      disabled: true,

      col: 3
    },
    cara_bayar: {
      label: 'Cara Bayar',
      widget: 'wtext',
      disabled: true,

      data: [],
      col: 3
    },
    ruangan: {
      widget: 'wtext',
      data: null,
      label: 'Ruangan',
      disabled: true,

      col: 3
    },
    kamar_kelas: {
      widget: 'wtext',
      data: null,
      label: 'Kamar Kelas',
      disabled: true,

      col: 3
    },
    jenis_kelamin: {
      widget: 'wradio',
      disabled: true,

      data: [
        { text: 'Laki-Laki', value: 'laki-laki' },
        { text: 'Wanita', value: 'wanita' }
      ],
      col: 6
    },
    dpjp_utama: {
      widget: 'wtext',
      data: null,
      disabled: true,

      label: 'Dokter DPJP Utama',
      col: 6
    },
    dpjp_pembantu: {
      widget: 'wtext',
      disabled: true,

      data: null,
      label: 'Dokter DPJP',
      col: 6
    },
    folio_id: {
      widget: 'wtext',
      data: null,
      disabled: true,

      col: 4,
      label: 'No Folio'
    },
    sub_unit_id: {
      widget: 'wtext',
      disabled: true,

      data: null,
      col: 2,
      label: 'Sub Unit Dokter'
    },
    reg_id: {
      widget: 'wtext',
      disabled: true,

      data: null,
      label: 'No Reg',
      col: 6
    }
  },
  form1: {
    label1: {
      label: 'Tanggal Operasi',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    tgl_operasi: {
      label: ' ',
      widget: 'wdatenormal',
      data: [],
      col: 3
    },
    label2: {
      label: 'Sifat',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    sifat: {
      widget: 'wradio',
      label: ' ',
      col: 3,
      data: [
        {
          text: 'Elektif',
          value: 'Elektif'
        },
        {
          text: 'Cito',
          value: 'Cito'
        }
      ]
    },
    label3: {
      label: 'Operator dr.',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    operator_dr: {
      widget: 'wtext',
      data: [],
      col: 3,
      label: ' '
    },

    label4: {
      label: 'Anestesiolog',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    anestesiolog: {
      widget: 'wtext',
      data: [],
      col: 3,
      label: ' '
    },

    label5: {
      label: 'Diagnosa Op. / Lokasi',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    diagnosa_op: {
      widget: 'wtext',
      data: [],
      col: 9,
      label: ' '
    }
  },
  form2: {
    label1: {
      label: '1. Bon Permintaan',
      widget: 'wlabelkiri',
      data: [],
      col: 6
    },
    bon_permintaan: {
      widget: 'wradio',
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 6,
      label: ' '
    },
    label2: {
      label: '2. Gelang Identitas Pasien',
      widget: 'wlabelkiri',
      data: [],
      col: 6
    },
    gelang_identitas: {
      widget: 'wradio',
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 6,
      label: ' '
    },
    label3: {
      label: '3. Informed Concent Bedah',
      widget: 'wlabelkiri',
      data: [],
      col: 6
    },
    informed_c_bedah: {
      widget: 'wradio',
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 6,
      label: ' '
    },
    label4: {
      label: '4. Informed Concent Anestesi',
      widget: 'wlabelkiri',
      data: [],
      col: 6
    },
    informed_c_anestesi: {
      widget: 'wradio',
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 6,
      label: ' '
    },
    label5: {
      label: '5. Asesmen Pra Anestesi',
      widget: 'wlabelkiri',
      data: [],
      col: 6
    },
    asesmen_pra_anestesi: {
      widget: 'wradio',
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 6,
      label: ' '
    },
    label6: {
      label: '6. Asesmen Pra Operasi',
      widget: 'wlabelkiri',
      data: [],
      col: 6
    },
    asesmen_pra_operasi: {
      widget: 'wradio',
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 6,
      label: ' '
    },
    label7: {
      label: '7. Pemeriksaan Penunjang',
      widget: 'wlabelkiri',
      data: [],
      col: 6
    },
    pemeriksaan_radiologi: {
      widget: 'wradio',
      data: [
        { text: 'X-RAY', value: 'X-RAY' },
        { text: 'MRI', value: 'MRI' },
        { text: 'USG', value: 'USG' },
        { text: 'EKG', value: 'EKG' },
        { text: 'EEG', value: 'EEG' },
        { text: 'Laboratorium', value: 'Laboratorium' }
      ],
      col: 6,
      label: ' '
    },
    label8: {
      label: '8. Persiapan Darah',
      widget: 'wlabelkiri',
      data: [],
      col: 6
    },
    persiapan_darah: {
      widget: 'wtext',
      data: [],
      col: 3,
      label: ' '
    },
    jam: {
      widget: 'wtext',
      data: [],
      col: 3,
      label: 'Jam'
    },
    label9: {
      label: '9. Informed Concent Transfusi',
      widget: 'wlabelkiri',
      data: [],
      col: 6
    },
    informed_c_transfusi: {
      widget: 'wradio',
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 6,
      label: ' '
    },
    label10: {
      label: '10. Pramedikasi :',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    jam_pramedikasi: {
      widget: 'wtext',
      data: [],
      col: 4,
      label: 'Jam'
    },
    nama_obat_pramedikasi: {
      widget: 'wtext',
      data: [],
      col: 4,
      label: 'Nama Obat'
    },
    label11: {
      label: '11. Lembar Penandaan Operasi',
      widget: 'wlabelkiri',
      data: [],
      col: 6
    },
    lembar_penandaan_operasi: {
      widget: 'wradio',
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 6,
      label: ' '
    },
    label12: {
      label: '12. Tanda Vital',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    td: {
      widget: 'wtext',
      data: [],
      col: 3,
      label: 'TD'
    },
    N: {
      widget: 'wtext',
      data: [],
      col: 3,
      label: 'N'
    },
    P: {
      widget: 'wtext',
      data: [],
      col: 3,
      label: 'P'
    },
    label13: {
      label: ' ',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    S: {
      widget: 'wtext',
      data: [],
      col: 9,
      label: 'S'
    },
    label14: {
      label: '13. Puasa, Mulai Jam',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    jam_puasa: {
      widget: 'wtext',
      data: [],
      col: 9,
      label: ' '
    },
    label15: {
      label: '14. Dilakukan Pemeriksaan',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    laven: {
      widget: 'wradio',
      data: [
        { text: 'Lavenment', value: 'Lavenment' },
        { text: 'Huknah', value: 'Huknah' },
        { text: 'Klisma', value: 'Klisma' }
      ],
      col: 9,
      label: ' '
    },
    label16: {
      label: '15. Persiapan Kulit/Cukur Dilakukan',
      widget: 'wlabelkiri',
      data: [],
      col: 5
    },
    persiapan_kulit: {
      widget: 'wradio',
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 7,
      label: ' '
    },
    label17: {
      label: '16. Protese/Alat Bantu/Kontak Lensa',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    protese: {
      widget: 'wtext',
      data: [],
      col: 3,
      label: 'Protese'
    },
    kontak: {
      widget: 'wtext',
      data: [],
      col: 3,
      label: 'Kontak Lensa Dilepas'
    },
    alat: {
      widget: 'wtext',
      data: [],
      col: 3,
      label: 'Alat Bantu Dengar Dilepas'
    },
    label18: {
      label: '17. Pace Maker (Alat Pacu Jantung) Terpasang',
      widget: 'wlabelkiri',
      data: [],
      col: 5
    },
    alat_pacu_jantung: {
      widget: 'wradio',
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 7,
      label: ' '
    },
    label19: {
      label: '18. Perhiasaan Dilepas',
      widget: 'wlabelkiri',
      data: [],
      col: 5
    },
    perhiasaan_dilepas: {
      widget: 'wradio',
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 7,
      label: ' '
    },
    label20: {
      label: '19. Cat Kuku Dihapus',
      widget: 'wlabelkiri',
      data: [],
      col: 5
    },
    cat_kuku: {
      widget: 'wradio',
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 7,
      label: ' '
    },
    label21: {
      label: '20. IV Line (Infus) Terpasang',
      widget: 'wlabelkiri',
      data: [],
      col: 5
    },
    infus: {
      widget: 'wradio',
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 7,
      label: ' '
    },
    label22: {
      label: '21. Kateter Urine Terpasang',
      widget: 'wlabelkiri',
      data: [],
      col: 5
    },
    kateter: {
      widget: 'wradio',
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 7,
      label: ' '
    }
  },
  form3: {
    label1: {
      label: '1. Laporan Operasi / Lap Anestesi',
      widget: 'wlabelkiri',
      data: [],
      col: 6
    },
    laporan_operasi: {
      widget: 'wradio',
      data: [
        { text: 'Lengkap', value: 'Lengkap' },
        { text: 'TIDAK lENGKAP', value: 'TIDAK lENGKAP' }
      ],
      col: 6,
      label: ' '
    },
    label2: {
      label: '2. Tanda-Tanda Vital',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    tanda_vital: {
      widget: 'wtext',
      data: [
      ],
      col: 8,
      label: ' '
    },
    label3: {
      label: ' ',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    td: {
      widget: 'wtext',
      data: [
      ],
      property: { attrs: { prefix: 'mmmHg', type: 'string' } },

      col: 3,
      label: 'TD'
    },
    RR: {
      widget: 'wtext',
      data: [
      ],
      property: { attrs: { prefix: 'x/mnt', type: 'string' } },

      col: 3,
      label: 'RR'
    },
    label4: {
      label: ' ',
      widget: 'wlabelkiri',
      data: [],

      col: 4
    },
    N: {
      widget: 'wtext',
      property: { attrs: { prefix: 'x/mnt', type: 'string' } },
      data: [
      ],
      col: 3,
      label: 'N'

    },
    S: {
      widget: 'wtext',
      property: { attrs: { prefix: 'C', type: 'string' } },
      data: [
      ],
      col: 3,
      label: 'S'
    },
    label5: {
      label: '3. Kesadaran',
      widget: 'wlabelkiri',
      data: [],

      col: 4
    },
    kesadaran: {
      widget: 'wtext',
      data: [
      ],
      col: 8,
      label: ' '
    },
    label6: {
      label: '4. Jalan Nafas',
      widget: 'wlabelkiri',
      data: [],

      col: 4
    },
    jalan_nafas: {
      widget: 'wradio',
      data: [
        { text: 'Bersih', value: 'Bersih' },
        { text: 'Tersumbat', value: 'Tersumbat' },
        { text: 'Parsial', value: 'Parsial' }
      ],
      col: 8,
      label: ' '
    },
    label7: {
      label: '5. Kulit',
      widget: 'wlabelkiri',
      data: [],

      col: 4
    },
    kulit: {
      widget: 'wtext',
      data: [
      ],
      col: 4,
      label: ' '
    },
    warna: {
      widget: 'wradio',
      data: [
        { text: 'Kemerahan', value: 'Kemerahan' },
        { text: 'Pucat', value: 'Pucat' },
        { text: 'Sianosis', value: 'Sianosis' }
      ],
      col: 4,
      label: ' '
    },
    label8: {
      label: ' ',
      widget: 'wlabelkiri',
      data: [],

      col: 4
    },
    turgor: {
      widget: 'wtext',
      data: [
      ],
      col: 8,
      label: 'Turgor'
    },
    label9: {
      label: '6. Klasifikasi Luka',
      widget: 'wlabelkiri',
      data: [],

      col: 4
    },
    klasifikasi_luka: {
      widget: 'wradio',
      data: [
        { text: 'Bersih', value: 'Bersih' },
        { text: 'Bersih Terkontaminasi', value: 'Bersih Terkontaminasi' },
        { text: 'Operasi Terkontaminasi', value: 'Operasi Terkontaminasi' },
        { text: 'Infeksi', value: 'Infeksi' }
      ],
      col: 8,
      label: ' '
    },
    label10: {
      label: '7. Drain',
      widget: 'wlabelkiri',
      data: [],

      col: 2
    },
    drain: {
      widget: 'wtext',
      data: [
      ],
      col: 3,
      label: ' '
    },
    lokasi: {
      widget: 'wtext',
      data: [
      ],
      col: 3,
      label: 'Lokasi'
    },
    jumlah_cairan: {
      widget: 'wtext',
      data: [
      ],
      col: 4,
      label: 'Jumlah Cairan'
    },
    label11: {
      label: '8. Infus',
      widget: 'wlabelkiri',
      data: [],

      col: 2
    },
    infus: {
      widget: 'wtext',
      data: [
      ],
      col: 3,
      label: ' '
    },
    jenis_cairan: {
      widget: 'wtext',
      data: [
      ],
      col: 3,
      label: 'Jenis Cairan'
    },
    lokasi_infus: {
      widget: 'wtext',
      data: [
      ],
      col: 4,
      label: 'Lokasi'
    },
    label12: {
      label: ' ',
      widget: 'wlabelkiri',
      data: [],

      col: 2
    },
    jumlah_tetesan: {
      widget: 'wtext',
      data: [
      ],
      col: 4,
      label: 'Jumlah Tetesan'
    },
    sisa_cairan: {
      widget: 'wtext',
      data: [
      ],
      col: 6,
      label: 'Sisa Cairan'
    },
    label13: {
      label: '9. Kathteter',
      widget: 'wlabelkiri',
      data: [],

      col: 3
    },
    kathteter: {
      widget: 'wtext',
      data: [
      ],
      col: 3,
      label: 'Urine'
    },
    jumlah_urine: {
      widget: 'wtext',
      data: [
      ],
      col: 3,
      label: 'Jumlah Urine'
    },
    warna_urine: {
      widget: 'wtext',
      data: [
      ],
      col: 3,
      label: 'Warna Urine'
    },
    label14: {
      label: '10. Produk Operasi',
      widget: 'wlabelkiri',
      data: [],

      col: 3
    },
    produk_operasi: {
      widget: 'wtext',
      data: [
      ],
      col: 3,
      label: ' '
    },
    implant: {
      widget: 'wradio',
      data: [
        { text: 'Pins', value: 'Pins' },
        { text: 'Plate', value: 'Plate' },
        { text: 'Scew', value: 'Scew' },
        { text: 'Kirsner', value: 'Kirsner' }
      ],
      col: 6,
      label: ' '
    },
    label15: {
      label: ' ',
      widget: 'wlabelkiri',
      data: [],

      col: 3
    },
    spesimen: {
      widget: 'wtext',
      data: [
      ],
      col: 3,
      label: 'Spesimen'
    },
    jaringan_tubuh: {
      widget: 'wtext',
      data: [
      ],
      col: 3,
      label: 'Jaringan Tubuh'
    },
    corpal: {
      widget: 'wtext',
      data: [
      ],
      col: 3,
      label: 'Corpal'
    },
    label16: {
      label: '11. Alat Transfer Keuangan',
      widget: 'wlabelkiri',
      data: [],

      col: 3
    },
    alat_transfer_keuangan: {
      widget: 'wtext',
      data: [
      ],
      col: 4,
      label: ' '
    },
    skor: {
      widget: 'wradio',
      data: [
        { text: 'Skor Aldert', value: 'Skor Aldert' },
        { text: 'Skor Stewart', value: 'Skor Stewart' },
        { text: 'Skor Bromage', value: 'Skor Bromage' }
      ],
      col: 4,
      label: ' '
    }
  },
  form4: {
    keterangan: {
      widget: 'wradio',
      data: [
        { text: 'HBSag', value: 'HBSag' },
        { text: 'B20 / HIV', value: 'B20 / HIV' },
        { text: 'Lain-lain', value: null, field: 'auto' }
      ],
      col: 12,
      label: ' '
    },
    label2: {
      label: 'Tanggal : ',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    tanggal: {
      widget: 'wdatenormal',
      data: [

      ],
      col: 4,
      label: ' '
    },
    jam: {
      widget: 'wtime',
      data: [

      ],
      col: 4,
      label: 'Jam'
    }
  },
  form5: {
    keterangan: {
      widget: 'wradio',
      data: [
        { text: 'PA', value: 'PA' },
        { text: 'Kultur Formulir', value: 'Kultur Formulir' },
        { text: 'PA / Formulir', value: 'PA / Formulir' },
        { text: 'Lain-lain', value: null, field: 'auto' }
      ],
      col: 12,
      label: ' '
    },
    label2: {
      label: 'Tanggal : ',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    tanggal: {
      widget: 'wdatenormal',
      data: [

      ],
      col: 4,
      label: ' '
    },
    jam: {
      widget: 'wtime',
      data: [

      ],
      col: 4,
      label: 'Jam'
    }
  }
}
